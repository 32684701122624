import firebase from 'firebase/app';
import 'firebase/auth';
import axios from "axios";


const url = `https://jadecapsapi.bits42.tech/api/v1/owners/`
const authenticationUrl = `https://jadecapsapi.bits42.tech/`


//Axios Interceptor Starts here

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("app_access_token");
  if(token){
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  

  config.headers['Content-Type'] = 'application/json';
  return config;
},
  (error) => {
    Promise.reject(error);
  }
);


axios.interceptors.response.use((response) => {
  return response;
},

function(error)
{
  const originalRequest = error.config;
  if(error.response.status === 401 && !originalRequest._retry && localStorage.getItem('app_refresh_token')){
    console.log(localStorage.getItem('app_refresh_token'))
    error.config._retry = true;


    return axios.post(`${authenticationUrl}api/v1/accounts/token/refresh/`,{
      refresh : localStorage.getItem('app_refresh_token')
    })

    .then(res => {
      console.log(res)
      //Setting up access token in Local Storage
      localStorage.setItem('app_access_token', res.data.access)
      //Changing Authorization
      axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('app_access_token');
      return axios(originalRequest)
    })
}
return Promise.reject(error);
}
);


//Axios Interceptor Ends here



//API call for signup
export const userRegister = async(apiBody) =>{
  
  console.log("Api body ha yeh",apiBody);

  
  
  console.log(apiBody);
  const apiUrl = `${authenticationUrl}api/v1/accounts/register/`;

  
  try{
    const res = await axios.post(apiUrl, apiBody);
    console.log("This is the res",res);
    return res.data;

  }
  catch(err){
    console.log(err);
    return err;
  }



}


//API call for financials

export const financials = async(i) => {
  console.log("financial getting =>");
  try{

    const apiUrl = `${url}get-owner-financials/${i}/`;
    const res = await axios.get(apiUrl);
    console.log('financial coming!', res.data);
    return res.data;


  }
  catch(err){
    console.log(err)
    return err;
  }
}
//API call for get Owner Account details

export const getOwnerAccountDetails = async() => {
  console.log('getting owner acc details');
  try{

    const apiUrl = `${authenticationUrl}api/v1/owners/OwnerAccount/`;
    const res = await axios.get(apiUrl);
    console.log('OwnerAcc', res.data.details);
    return res.data;


  }
  catch(err){
    console.log(err)
    return err;
  }
}

export const postOwnerAccountDetails = async(body) => {
  try{

    const apiUrl = `${authenticationUrl}api/v1/owners/OwnerAccount/`;
    const res = await axios.post(apiUrl, body);
    console.log('OwnerAcc', res.data.details);
    return res.data;


  }
  catch(err){
    console.log(err)
    return err;
  }
}



//API call for login

export const userLogin = async(apiBody) => {
  const apiUrl = `${authenticationUrl}api/v1/accounts/api/token/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}
/////////////////// forgot pwd
export const forgotPwd = async(apiBody) => {
  const apiUrl = `${authenticationUrl}api/v1/accounts/password_reset/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}
/////////////////// forgot pwd reset
export const resetPwd = async(apiBody) => {
  console.log("yo")

  const apiUrl = `${authenticationUrl}api/v1/accounts/password_reset/confirm/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}
/////////////////////////////// listing toggle
export const toggleListing = async(apiBody) => {
  console.log("yo")

  const apiUrl = `${url}toggle_listing_status/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}
// API for password change

export const changePassword = async(apiBody) => {
  const apiUrl = `${authenticationUrl}api/v1/accounts/change-password/`
  console.log('API Body pwdchnge:', apiBody);


  try{
    const res = await axios.put(apiUrl, apiBody);
    console.log(res.data);
    return res.data;

  }
  catch(err){
    throw new Error(err);
    return err;

  }
}

// Sign Up Firebase using Email
export const  firebaseEmailSignup = async (email, password) =>{

      
    if(!email.includes('@')){
      
      return({err:{msg:"Email already exists"}})
      
    }

     
    else{
        try{
        const user = await firebase.auth().createUserWithEmailAndPassword(email, password)
        console.log(user.user.refreshToken)
        return(user.user) 
        }
         
        catch(err){
        console.log(err)
        return({err:{msg:"Email already exists"}})
        }

    }


  }


// Login Firebase using Email
export const loginByEmail = async (email, password) =>{
    
    try{
    const result = await firebase.auth().signInWithEmailAndPassword(email, password)
    
      //console.log("Successfully logged in!")
      //console.log('Email access token:', result.credential.accessToken)
      var user = result.user
      return user
    }

    catch(err){      
      console.log(err)
      return({err: {msg : "Invalid credentials"}})
    }
  }


// Login Firebase using Facebook
  export const loginByFacebook = async() =>{
    try{
      let provider = new firebase.auth.FacebookAuthProvider();
      let result = await firebase.auth().signInWithPopup(provider)
      
      var token = result.credential.accessToken;
      var user = result.user
      console.log("This is Facebook login generated token", token)
      console.log(user)
      return user

      }
      catch(err){
          console.log(err)
          return ({err: {msg:'Cannot process request'}})
      } 
    }

// Login Firebase using Google
    export const loginByGoogle = async() => {
      try{
        let provider = new firebase.auth.GoogleAuthProvider();
        let result = await firebase.auth().signInWithPopup(provider)

        var token = result.credential.accessToken
        
        var user = result.user
        console.log("This is Google login generated token", token)
        console.log(user)
        return user
  
        }
        catch(err){
            console.log(err)
            return ({err: {msg:'Cannot process request'}})
        } 
      
    }

// Logout API  for any kind of authentication 
export const logout = async() =>{
  
  try{
  const result = await firebase.auth().signOut()
  console.log("User logged out:", result.user)
  var user = result.user
  return user

  }
  catch(err){
    console.log(err)
    return ({err: {msg:'Cannot process request'}})

  }
}

// API for Email verification 
export const verifyEmail = async() =>{
  try{
    const user = firebase.auth().currentUser
    user.sendEmailVerification()
    console.log('Verification email sent!')
    return({success:"true", msg:"Verification email sent!"})
  }
  catch(err){
    console.log(err)
    return({success:"false", err})
  }
}


// API call for user details

export const getUserDetails = async() => {
  console.log('Entering user details');
  try{

    const apiUrl = `${authenticationUrl}api/v1/accounts/getUser`;
    const res = await axios.get(apiUrl);
    console.log('Yeh aye dadaji!', res.data.user);
    return res.data.user;


  }
  catch(err){
    console.log(err)
    return err;
  }
}












//API call for first listing page

export const firstListingPage = async(firstPageDetails) => {
  console.log('First Page Details:', firstPageDetails);
  try{
    const res = await axios.post(`${url}house-basic-details/`, firstPageDetails);
    console.log(res)
    return res.data;


  }catch(err){
    console.log(err);
    return err;

  }

}

//APi call for second listing page
export const secondListingPage = async(secondPageDetails) => {

 
  console.log('Second Page Details:', secondPageDetails);
  try{
    const res = await axios.post(`${url}house-property-details/`, secondPageDetails);
    console.log(res)
    return res.data
  }
  catch(err){
    console.log('This is error',err)
    return err;

  }
}

//API call for third listing page
export const thirdListingPage = async(id,imageLink, images) =>{
  const apiUrl = `${url}house-add-image/`
  console.log('Third Page Details:', imageLink);
  console.log('Third Page img Details:', images);
  try{

    var bodyFormData = new FormData();
    bodyFormData.append('id', id);
    bodyFormData.append('house_id', id);

    imageLink.map((image, index) => {
      bodyFormData.append(`file_${index+1}`, image)
      bodyFormData.append(`caption_${index+1}`, images[index]["caption"])
    })





    const res = await axios({method : "post", url:apiUrl, data: bodyFormData, headers: { "Content-Type": "multipart/form-data" }})
    console.log(res)
    return res.data

  }catch(err){
    console.log(err)
    return err;

  }
}

// API call for fifth page listing amenities (GET)
export const getActiveAminities = async() => {
  console.log('Using active amenities!');
  try{
    const apiUrl = `${url}amn_view/`;
    const res = await axios.get(apiUrl);
    console.log(res);
    return res.data;

  }
  catch(err){
    console.log(err);
    return err;
  }
}


export const getMiscAmenities = async() => {
  console.log('Getting misc amenities >>');
  try{
    const apiUrl = `${url}misc_amn_view/`;
    const res = await axios.get(apiUrl);
    console.log(res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}


export const getOwnerListingView = async(pageNo) => {
  console.log('Inside owner listing view')
  try{
    const apiUrl = `https://jadecapsapi.bits42.tech/api/v1/owners/owners-listings/?page=${pageNo}`;
    const res = await axios.get(apiUrl);
    console.log(res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}


export const getOwnerReservations = async(pageNo) => {
  console.log('Inside reservation');
  try{
    const apiUrl = `${url}reservation_view/${pageNo}/`;
    const res = await axios.get(apiUrl);
    console.log(res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}


//Get Calendar API

export const getCalenderApi = async(property) => {
  console.log('Inside calendar');

  if(property){
    try{
      var apiUrl;
      apiUrl = `${url}indiv_calender/${property}/`;
      const res = await axios.get(apiUrl);
      console.log(res);
      return res.data;
    }
    catch(err){
      console.log(err);
      return err;
    }
  }
}

export const fourthListingPage = async(apiShortStayDetails, apiLongStayDetails, pricingDone, longPresent, shortPresent) => {
  try{
    const apiUrlShort = `${url}set-pricing-short-stay/`;
    let lDone = false;
    let sDone = false;
    let resShort = {data:{
      success:false,
    }}
    if(pricingDone.shortStay && !shortPresent){resShort = await axios.post(apiUrlShort,apiShortStayDetails);
      console.log(resShort.data);
      sDone = true;
    }
    else if(pricingDone.shortStay && shortPresent){
      resShort = await axios.put(apiUrlShort,apiShortStayDetails)
      sDone = true;
    }
    else if (!pricingDone.shortStay && shortPresent) {
      let del = await axios.put(`${url}delete-short-stay/`,{id:parseInt(apiShortStayDetails.id)});
    }

     

    const apiUrlLong = `${url}set-pricing-long-stay/`;
    let resLong = {data:{
      success:false
    }} 
    if(pricingDone.longStay && !longPresent){resLong = await axios.post(apiUrlLong,apiLongStayDetails);
      console.log(resLong.data);
      lDone = true
    }
    else if (pricingDone.longStay && longPresent){
      resLong = await axios.put(apiUrlLong,apiLongStayDetails)
      lDone = true;
    }
    else if (!pricingDone.longStay && longPresent) {
      let del = await axios.put(`${url}delete-long-stay/`,{id:parseInt(apiLongStayDetails.id)});
    }

    let resData={resShort: resShort.data, resLong: resLong.data, lDone: lDone,sDone:sDone}
    // console.log(resData)
    return resData; 
  }
  catch(err){
    console.log(err);
    return err;
  }
}


export const latestReservations = async() => {

  try{
    const apiUrl = `${url}latest_check_in_out/`;
    const res = await axios.get(apiUrl);
    console.log(res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }

}


export const balanceGraphApi = async() => {
  try{
    const apiUrl = `${url}balance_graph/`;
    const res = await axios.get(apiUrl);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}

export const fifthListingPage = async(apiDetails) => {
  try{
    const apiUrl = `${url}misc_amen/`;
    const res = await axios.post(apiUrl,apiDetails);
    console.log(res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }

}





//API for getting the details
export const fetchListingData = async(id) => {
  const apiUrl = `${url}fetch_update_data/${id}`;
  
  try{
    const res = await axios.get(apiUrl);
    console.log('Data fetched:',res);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}


export const fetchRevGeocodeDetails = async(long, lat) =>{
  try{
    const mapAccessToken = "pk.eyJ1IjoicmlzaGktcmFqLXJveSIsImEiOiJja3N1ZGdsNmwxZXZrMzJvZHhlMDh1M2tjIn0.V1a6wQRKv3J-fNnSGWY6ig";
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${mapAccessToken}`
    console.log(apiUrl);
    const res = await axios.get(apiUrl);
    console.log('Geocoding rest api:', res.data);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}

//Images
export const getHouseImages = async(id) => {
  try{
    const apiUrl = `${url}get-house-images/${id}/`;
    const res = await axios.get(apiUrl);
    return res.data;

  }
  catch(err){
    console.log(err);
    return err;
  }
}

//
export const deleteHouseImage = async(apiDetails) => {
  try{
    const apiUrl = `${url}house-delete-image/`;
    const res = await axios.post(apiUrl,apiDetails);
    return res.data;
  }
  catch(err){
    console.log(err);
    return err;
  }
}

//API call for third listing page
export const uploadImageOneByOne = async(id,imageLink, images, indexNo) =>{
  const apiUrl = `${url}house-add-image/`
  console.log('Third Page Details:', imageLink);
  console.log('Third Page img Details:', images);
  try{

    var bodyFormData = new FormData();
    bodyFormData.append('id', id);
    bodyFormData.append('house_id', id);

    bodyFormData.append(`file_${indexNo+1}`, imageLink[indexNo]);
    bodyFormData.append(`caption_${indexNo+1}`, images[indexNo]["caption"])

    const res = await axios({method : "post", url:apiUrl, data: bodyFormData, headers: { "Content-Type": "multipart/form-data" }})
    console.log(res)
    return res.data

  }catch(err){
    console.log(err)
    return err;

  }
}

export const verifyEmailBeta = async (apiBody) => {
  const res = await axios.post(`https://jadecapsapi.bits42.tech/api/v1/accounts/verify-again/`, apiBody);
  console.log(res.data)
  return res.data;
}